<div class="wrapper"
  [class.ghost]="isLoading"
  [class.square]="shape === 'square'"
  [class.with-border]="withBorder"
  [style.width]="size"
  [style.height]="size">

  <ng-container *ngIf="!isLoading; else isLoadingTemplate">
    <div *ngIf="imageUrl; else initialsTemplate"
      class="avatar"
      aria-hidden="true"
      [class.contain]="useBackgroundSizeContain"
      [lazyLoad]="imageUrl"
      (onStateChange)="lazyLoadOnStatechange($event)">
    </div>

    <ng-template #initialsTemplate>
      <div *ngIf="name"
        class="avatar"
        [style.background-color]="initialsBgColor">
        <svg xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          x="0"
          y="0"
          viewBox="0 0 40 40"
          focusable="false">
          <style>
            @import url('https://fonts.googleapis.com/css?family=Roboto:500');
          </style>
          <text [style.fill]="initialsTextColor"
            [attr.x]="'50%'"
            [attr.y]="'50%'"
            font-family="Roboto"
            font-size="14px"
            font-weight="500"
            letter-spacing="1"
            dominant-baseline="central"
            text-anchor="middle"
            focusable="false">
            {{ initials }}
          </text>
        </svg>
      </div>
    </ng-template>

    <div class="avatar default">
      <accredible-icon [icon]="'user1'"
        [style.font-size]="avatarDefaultFontSize">
      </accredible-icon>
    </div>
  </ng-container>

  <ng-template #isLoadingTemplate>
    <div class="avatar"></div>
  </ng-template>
</div>
