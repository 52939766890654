import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateChange } from 'ng-lazyload-image';

let nextUniqueId = 0;

@Component({
  selector: 'accredible-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [`./avatar.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  isLoading = true;
  @Input()
  withBorder = true;
  @Input()
  imageUrl: string | null;
  @Input()
  useBackgroundSizeContain = false;
  @Input()
  shape: 'circle' | 'square' = 'circle';

  avatarDefaultFontSize: string;
  initials: string;
  initialsBgColor: string;

  // a11y: Colors set below meet required contrast ratios https://webaim.org/resources/contrastchecker/
  initialsTextColor = '#ffffff';
  allowedColors = [
    '#6472B9',
    '#4200FF',
    '#B15703',
    '#227B66',
    '#8E56C7',
    '#DC00AC',
    '#3F8409',
    '#6368DD',
    '#B11500',
    '#8D6862',
  ];

  private _name: string;

  get name(): string {
    return this._name;
  }

  @Input()
  set name(name: string) {
    this._name = name;
    this._name && (this.initials = _nameToInitials(this._name));
    this.initialsBgColor = this._setupColors();
  }

  private _size: string;

  get size(): string {
    return this._size;
  }

  @Input()
  set size(size: string) {
    this._size = size || '40px';

    // Calculate the font-size to be used in the icon of the default version of the avatar
    const sizeNumber = Number(this.size.replace(/px$/, ''));
    this.avatarDefaultFontSize = `${sizeNumber - sizeNumber * 0.3}px`;
  }

  lazyLoadOnStatechange(event: StateChange): void {
    switch (event.reason) {
      case 'loading-failed':
        this.imageUrl = null;
        break;
    }
  }

  private _setupColors(): string {
    nextUniqueId++;
    return this.allowedColors[nextUniqueId % this.allowedColors.length];
  }
}

const _nameToInitials = (name: string): string => {
  let initials = '';

  const names = name.trim().split(/\s+/gi);
  names.forEach((name) => {
    initials += name[0].toLocaleUpperCase() || '';
  });

  return initials.substring(0, 2); // Max of 2 char
};
